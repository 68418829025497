import React from "react"
import theme from "theme"
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Головна | Ласкаво просимо до Sovix Elwin</title>
        <meta name={"description"} content={"Посильте свою подорож"} />
        <meta
          property={"og:title"}
          content={"Головна | Ласкаво просимо до Sovix Elwin"}
        />
        <meta property={"og:description"} content={"Посильте свою подорож"} />
        <meta
          property={"og:image"}
          content={"https://win.sovixelwin.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://win.sovixelwin.com/img/4729230.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="0 0 0 0" quarkly-title="Statistics-7">
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          md-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            md-width="100%"
            md-margin="0px 0px 50px 0px"
            sm-margin="0px 0px 35px 0px"
          >
            <Image
              src="https://win.sovixelwin.com/img/1.jpg"
              display="block"
              width="auto"
              height="500px"
              max-width="100%"
              object-fit="cover"
              object-position="30% 50%"
              sm-height="400px"
            />
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            padding="0px 0px 0px 90px"
            display="flex"
            flex-direction="column"
            justify-content="center"
            lg-padding="0px 0px 0px 50px"
            md-width="100%"
            md-padding="0px 35px 80px 35px"
            sm-padding="0px 0 80px 0"
          >
            <Text
              margin="0px 0px 10px 0px"
              color="--primary"
              font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
            >
              Розпочніть свою фітнес-одіссею
            </Text>
            <Text
              margin="0px 0px 30px 0px"
              color="--dark"
              font="normal 700 42px/1.2 --fontFamily-sans"
            >
              Sovix Elwin
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
              color="#75767d"
            >
              Відкрийте для себе Sovix Elwin, де кожне тренування - це
              можливість кинути виклик самому собі та переосмислити свої межі.
              Наш тренажерний зал - це більше, ніж просто місце для тренувань,
              це притулок для особистісного зростання, що пропонує збалансоване
              поєднання фітнес-інновацій та мотиваційної підтримки. Незалежно
              від того, чи ви новачок, чи просунутий спортсмен, Совікс Елвін
              допоможе вам досягти прогресу у вашій фітнес-подорожі.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-13"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          lg-width="100%"
          sm-margin="0px 0px 0px 0px"
          display="flex"
          width="40%"
          align-items="flex-start"
        >
          <Box
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 60% 0px"
            width="100%"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              width="100%"
              right={0}
              min-height="100%"
              top="auto"
              left={0}
              bottom="0px"
              src="https://win.sovixelwin.com/img/2.jpg"
              object-fit="cover"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex-direction="row"
          flex-wrap="wrap"
          width="60%"
          lg-width="100%"
          lg-order="-1"
        >
          <Box
            display="flex"
            align-items="flex-start"
            lg-width="100%"
            sm-margin="0px 0px 0px 0px"
            width="100%"
            flex-direction="column"
            lg-align-items="center"
            lg-margin="0px 0px 0px 0px"
            sm-padding="0px 0px 0px 0px"
            padding="8px 16px 8px 16px"
          >
            <Text
              lg-text-align="center"
              sm-font="--headline3"
              sm-margin="0px 0px 20px 0px"
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--headline2"
              md-text-align="left"
            >
              Чому Sovix Elwin?
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            lg-width="100%"
            lg-align-items="center"
            lg-margin="0px 0px 0px 0px"
            sm-padding="0px 0px 0px 0px"
            width="50%"
            flex-direction="column"
            padding="16px 16px 16px 16px"
            display="flex"
            sm-margin="0px 0px 0px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--grey"
              font="--base"
              lg-text-align="center"
              sm-margin="0px 0px 30px 0px"
              md-text-align="left"
            >
              Найсучасніше обладнання: Наш тренажерний зал, обладнаний
              найновішими фітнес-технологіями та просторими зонами для
              тренувань, розроблений таким чином, щоб задовольнити кожен аспект
              вашої фітнес-програми.
              <br />
              <br />
              Індивідуальні рекомендації з фітнесу: Наша команда досвідчених
              тренерів прагне створити персоналізовані плани тренувань, які
              відповідають вашим фітнес-цілям і способу життя.
            </Text>
          </Box>
          <Box
            lg-margin="0px 0px 0px 0px"
            sm-padding="0px 0px 0px 0px"
            padding="16px 16px 16px 16px"
            display="flex"
            lg-align-items="center"
            align-items="flex-start"
            lg-width="100%"
            sm-margin="0px 0px 0px 0px"
            width="50%"
            flex-direction="column"
            lg-padding="0px 16px 16px 16px"
          >
            <Text
              lg-text-align="center"
              sm-margin="0px 0px 30px 0px"
              margin="0px 0px 0px 0px"
              color="--grey"
              font="--base"
              md-text-align="left"
            >
              Енергійне співтовариство: "Совікс Ельвін" - це більше, ніж
              тренажерний зал, це спільнота. Беріть участь у групових заняттях з
              фітнесу, спеціальних заходах та заводьте міцну дружбу з фітнесу.
              <br />
              <br />
              Прозоре членство: Насолоджуйтесь повним доступом до всіх наших
              можливостей з простими планами членства та без прихованих
              платежів.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Description-9"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-margin="0px 0px 30px 0px"
          sm-padding="0px 0px 0px 0px"
          align-items="flex-start"
          lg-align-items="center"
          flex-direction="column"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
          padding="24px 24px 24px 24px"
          justify-content="space-between"
          display="flex"
          width="50%"
        >
          <Box>
            <Text
              margin="0px 0px 36px 0px"
              color="--darkL2"
              font="--headline2"
              lg-text-align="center"
              sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            >
              Залишайтеся на зв'язку
            </Text>
          </Box>
          <Text
            margin="0px 0px 0px 0px"
            color="--grey"
            font="--lead"
            lg-text-align="center"
          >
            Пориньте у світ, де фітнес-цілі досягаються з ентузіазмом та
            підтримкою. "Совікс Ельвін" - це не просто тренажерний зал, це
            початок нового розділу у вашій фітнес-історії. Розпочніть свій шлях
            з нами вже сьогодні.
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="24px 24px 24px 24px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 60% 0px"
            width="100%"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              width="100%"
              right={0}
              min-height="100%"
              top="auto"
              left={0}
              bottom="0px"
              src="https://win.sovixelwin.com/img/3.jpg"
              object-fit="cover"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
